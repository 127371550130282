.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.5);
  z-index: 9999;
}

.w-16 {
  width: 4rem;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 151 119 / var(--tw-bg-opacity));
}

.dotPulseLoader > div {
  animation: sk-bouncedelay 1.4s ease-in-out infinite both;
  animation-delay: 0s;
  border-radius: 50%;
  display: inline-block;
  height: 8px;
  width: 8px;
}

.dotPulseLoader .bounce1 {
  animation-delay: -0.32s;
}

.dotPulseLoader .bounce2 {
  animation-delay: -0.16s;
}
