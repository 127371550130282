/* @src/components/Modal.module.css */

.darkBG {
  background-color: rgba(0, 0, 0, 0.8);
  width: 100vw;
  height: 100vh;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
}

.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.modal {
  width: 400px;
  min-height: 165px;
  background: white;
  color: white;
  z-index: 10;
  border-radius: 10px;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalSm {
  width: 250px;
  min-height: 165px;
}

.modalLg {
  width: 650px;
  min-height: 165px;
}

.modalXl {
  width: 1000px;
  min-height: 165px;
}

.modalHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  padding: 1rem 1.5rem;
}

.heading {
  margin: 0;
  color: #2c3e50;
  font-weight: 500;
  font-size: 18px;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 14px;
  color: #2c3e50;
  text-align: center;
}

.noHeader {
  padding: 2rem;
}

.actionIcon {
  height: 2rem;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items:center;
  gap: 0.5rem;
  padding: 0.5rem 0;
  min-height: 70px;
}

.modalActions {
  width: 100%;
  padding-bottom: 2rem;
}

.actionsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  align-self: flex-end;
}
.closeHoverBtn {
  position: absolute;
  right: 1.5rem;
  top: 1rem;
}
.closeBtn img{
  width: 12px;
  height: 12px;
}

.confirmBtn {
  cursor: pointer;  
  font-weight: 700;
  font-size: 12px;
  background: linear-gradient(90deg, rgb(247, 112, 125) 0%, rgb(242, 145, 115) 100%);
  border-radius: 10px;
  color: rgb(255, 255, 255);
  border: transparent;
  min-width: 110px;
  min-height: 38px;
  margin-bottom: 0px;
  padding: 3px;
}

.cancelBtn {
  cursor: pointer;
  font-weight: 700;
  font-size: 12px;
  background: transparent;
  border-radius: 10px;
  color: rgb(255, 151, 119);
  border: 1px solid rgb(255, 151, 119);
  min-width: 110px;
  min-height: 38px;
  margin-left: 1rem;
  margin-bottom: 0px;
}